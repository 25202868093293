import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-violet-600">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="text-4xl font-bold">
            <p>
              Experienced DEVOPS/SRE Professional with a Bachelor's Degree in
              Computer Science
            </p>
          </div>
          <div>
            <p>
              I have a passion for problem-solving, writing code, and constantly
              expanding my skillset and knowledge. In my opinion, the automation
              of manual processes and the development of self-service tools are
              crucial for boosting efficiency and productivity. I believe that
              observability and monitoring are among the most important factors
              in maintaining reliable and scalable production systems. It is
              essential to collect and analyze data intelligently to ensure
              timely identification of critical events while minimizing noise
              and unnecessary information. Additionally, I enjoy collaborating
              with others and sharing my knowledge.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
