import React from "react";
import ArgoCD from "../assets/argo.png";
import AWS from "../assets/aws.png";
import Coralogix from "../assets/coralogix.png";
import Datadog from "../assets/datadog.png";
import Docker from "../assets/docker.png";
import Git from "../assets/git.png";
import Golang from "../assets/golang.png";
import Grafana from "../assets/grafana.png";
import Jenkins from "../assets/jenkins.png";
import Kibana from "../assets/kibana.png";
import Mongo from "../assets/mongo.png";
import MySQL from "../assets/mysql.png";
import SQLite from "../assets/sqlite.png";
import Prometheus from "../assets/prometheus.png";
import Python from "../assets/python.png";
import Terraform from "../assets/terraform.png";
import ReactImg from "../assets/react.png";
import K8S from "../assets/k8s.png";
import Linkerd from "../assets/linkerd.png";
import Vault from "../assets/vault.png";

const Skills = () => {
  return (
    <div name="skills" className="w-full h-screen bg-[#0a192f] text-gray-300">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-violet-600 ">
            Skills
          </p>
          <p className="py-4 font-bold text-xl">
            See my resume for full list of skills and technologies I've worked
            with.
          </p>
        </div>
        <div className="grid grid-cols-4 gap-4">
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={K8S} alt="K8s icon" />
            <p className="my-4">K8s</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={AWS} alt="AWS icon" />
            <p className="my-4">AWS</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-20 mx-auto"
              src={Terraform}
              alt="Terraform icon"
            />
            <p className="my-4">Terraform</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-20 mx-auto"
              src={Prometheus}
              alt="Prometheus icon"
            />
            <p className="my-4">Prometheus</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Grafana} alt="Grafana icon" />
            <p className="my-4">Grafana</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Python} alt="Python icon" />
            <p className="my-4">Python</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Golang} alt="Golang icon" />
            <p className="my-4">Golang</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Jenkins} alt="Jenkins icon" />
            <p className="my-4">Jenkins</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Vault} alt="Jenkins icon" />
            <p className="my-4">Hashicorp Vault</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={ArgoCD} alt="ArgoCD icon" />
            <p className="my-4">ArgoCD</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img
              className="w-20 mx-auto"
              src={Coralogix}
              alt="Coralogix icon"
            />
            <p className="my-4">Coralogix</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Datadog} alt="Datadog icon" />
            <p className="my-4">Datadog</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Kibana} alt="Kibana icon" />
            <p className="my-4">Kibana</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Linkerd} alt="MySQL icon" />
            <p className="my-4">Linkerd</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={Mongo} alt="Mongo icon" />
            <p className="my-4">Mongo</p>
          </div>
          <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={SQLite} alt="MySQL icon" />
            <p className="my-4">SQLite</p>
          </div>
          {/* <div className="shadow-md shadow-[#040c16] hover:scale-110 duration-500">
            <img className="w-20 mx-auto" src={ReactImg} alt="REACT icon" />
            <p className="my-4">REACT</p>
          </div> */}

          {/* Add more here */}
        </div>
      </div>
    </div>
  );
};

export default Skills;
